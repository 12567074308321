<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <h1>Editar empresa</h1>
        </div>
      </v-col>
    </v-row>

    <EmpresaForm
      :empresa="empresa"
      :provincias="provincias"
      :isUpdate="true"
      :isLoading="isLoading"
      @process-empresa="actualizarEmpresa"
    ></EmpresaForm>
  </v-container>
</template>

<script>
import EmpresaForm from "@/components/empresa/empresa.form.vue";
import EmpresaAPI from "@/api/empresas";

export default {
  name: "empresa.update",
  components: {
    EmpresaForm,
  },
  props: {
    empresa: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      provincias: [],
      isLoading: false,
    };
  },

  methods: {
    async actualizarEmpresa(empresa) {
      try {
        this.isLoading = true;
        const response = await EmpresaAPI.update(empresa);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: `Actualizado`,
          icon: "mdi-check",
          timeout: 2500,
        });
        this.isLoading = false;
      } catch (error) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `Problema al actualizar la empresa'`,
          icon: "mdi-bug",
          timeout: 2500,
        });
        this.isLoading = false;
      }
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  mounted() {
    this.fetchProvincias();
  },
};
</script>

<style lang="scss" scoped></style>
